import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import HttpService from "../../hooks/Https-services";
import PageRoutes, {
  APIRoutes,
  INSIDER_PLAN_ID,
  UserRoles,
  WHIZZARD_TYPE,
} from "../../utils/constants";
import ShortContentCard from "./ShortContentCard";
import Grid2 from "@mui/material/Unstable_Grid2";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import { getCurrentAuth } from "../../hooks/http-server";
import { toast } from "react-toastify";
import useConfig from "../../hooks/useConfig";
import LatestVideo from "./LatestVideo";
import ChannelHighLights from "./ChannelHighLights";
const ShortContent = () => {
  const [clips, setClips] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoList, setVideoList] = useState<any>([]);
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const [slider, setSlider] = useState([]);
  const configData = useConfig();
  const [planDetails, setPlanDetails] = useState<any>(null);
  const userDetails = useCommonDetails();
  const auth = getCurrentAuth();
  const [channels, setChannels] = useState<any>([]);
  const GetUserSubscriptionData = useCallback(async () => {
    if (!auth.AccessToken) {
      return;
    }
    try {
      setIsLoading(true);
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetails(res?.data?.data?.subscription);
        setPlanDetails(res?.data?.data);
        setIsLoading(false);
        return res?.data?.data;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    if (auth.AccessToken) {
      GetUserSubscriptionData();
    }
  }, [GetUserSubscriptionData, auth.AccessToken]);

  const hasPermition = () => {
    if (
      (planDetails?.plan_type_id == INSIDER_PLAN_ID &&
        !subscriptionDetails?.pause_collection) ||
      [
        UserRoles.HTP_ADMIN,
        UserRoles.LEAGUE_ADMIN,
        UserRoles.FREE_WATCH_D,
      ].includes(userDetails?.UserRoleID)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const GetChannels = useCallback(async () => {
    const result = await HttpService.getapi(
      APIRoutes.ChannelOption.replace(":id", configData.customer)
    );
    setChannels(result.data.data);
  }, []);
  useEffect(() => {
    GetChannels();
  }, [GetChannels]);
  if (isLoading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 4,
        px: 8,
      }}
    >
      <Box
        sx={{
          opacity: !hasPermition() ? 0.3 : 1,
          backgroundColor: !hasPermition() ? configData.primary : "",
          filter: !hasPermition() ? "blur(1px)" : "",
        }}
      >
        <LatestVideo hasPermition={hasPermition} />
        <Divider sx={{ mt: 3 }} />
        <Box>
          {channels.map((channel: any) => (
            <Box key={channel.id}>
              <ChannelHighLights
                hasPermition={hasPermition}
                channel_id={channel.channel_id}
                channel_name={channel.channel_name}
                type_name={WHIZZARD_TYPE[3]}
                type={3}
              />
            </Box>
          ))}
          <Box sx={{ py: 2 }}>
            {channels.map((channel: any) => (
              <Box key={channel.id}>
                <ChannelHighLights
                  hasPermition={hasPermition}
                  channel_id={channel.channel_id}
                  channel_name={channel.channel_name}
                  type_name={WHIZZARD_TYPE[2]}
                  type={2}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {!hasPermition() && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            right: 0,
            width: 1,
            bottom: "50%",
            left: 0,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: configData.primary,
              fontSize: 18,
              fontWeight: 600,
            }}
            variant="body1"
          >
            {!auth.AccessToken ? (
              <Button
                variant="contained"
                onClick={() => navigate(PageRoutes.Login)}
              >
                Log in to see the highlights
              </Button>
            ) : (
              "This feature is only available to insiders"
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ShortContent;

import { Box, IconButton, Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LinkIcon from "@mui/icons-material/Link";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useConfig from "../../../hooks/useConfig";
interface SocialShareProps {
  url: string;
  title: string;
}

const SocialShare = ({ url, title }: SocialShareProps) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const configData = useConfig();
  const shareLinks = {
    whatsapp: `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedUrl}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Tooltip title="Share on WhatsApp" arrow placement="top">
        <IconButton
          onClick={() => window.open(shareLinks.whatsapp, "_blank")}
          sx={{
            bgcolor: configData.primary,
            color: "white",
            "&:hover": { bgcolor: configData.primary },
          }}
        >
          <WhatsAppIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Share on Facebook" arrow placement="top">
        <IconButton
          onClick={() => window.open(shareLinks.facebook, "_blank")}
          sx={{
            bgcolor: configData.primary,
            color: "white",
            "&:hover": { bgcolor: configData.primary },
          }}
        >
          <FacebookIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Share on X" arrow placement="top">
        <IconButton
          onClick={() => window.open(shareLinks.twitter, "_blank")}
          sx={{
            bgcolor: configData.primary,
            color: "white",
            "&:hover": { bgcolor: configData.primary },
          }}
        >
          <FontAwesomeIcon icon={faXTwitter as IconProp} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Share on LinkedIn" arrow placement="top">
        <IconButton
          onClick={() => window.open(shareLinks.linkedin, "_blank")}
          sx={{
            bgcolor: configData.primary,
            color: "white",
            "&:hover": { bgcolor: configData.primary },
          }}
        >
          <LinkedInIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Copy Link" arrow placement="top">
        <IconButton
          onClick={copyToClipboard}
          sx={{
            bgcolor: configData.primary,
            color: "white",
            "&:hover": { bgcolor: configData.primary },
          }}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

interface MetaTagsProps {
  title: string;
  description: string;
  image: string;
  url: string;
}

export const MetaTags = ({ title, description, image, url }: MetaTagsProps) => {
  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Open Graph meta tags (Facebook, WhatsApp, etc.) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="video.other" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default SocialShare;

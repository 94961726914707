import { Box, Typography } from "@mui/material";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import moment from "moment";

const ShortContentCard = ({ clip, showDate = true }: any) => {
  return (
    <Box>
      <Box>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <Box
            component={"img"}
            sx={{
              height: 180,
              width: 1,
              objectFit: "cover",
              transition: "transform 0.3s ease-in-out",

              "&:hover": {
                transform: "scale(1.03)",
                cursor: "pointer",
              },
            }}
            src={clip.Thumbnail}
            alt={clip.Title}
          />
          <Box sx={{ position: "absolute", bottom: 8, left: 8 }}>
            <PlayCircleFilledWhiteOutlinedIcon sx={{ color: "#fff" }} />
          </Box>
        </Box>

        <Box sx={{ mt: 1 }}>
          {!showDate && (
            <Typography
              sx={{
                fontWeight: 600,
                fontStyle: "normal",
                textTransform: "none",
              }}
              variant="body1"
            >
              {clip?.Description}
            </Typography>
          )}

          <Typography
            sx={{
              fontWeight: showDate ? 600 : 400,
              fontStyle: "normal",
              textTransform: "none",
            }}
            variant={showDate ? "body1" : "body2"}
          >
            {clip.Title}
          </Typography>

          {showDate && (
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "normal",
                color: "#474747",
                textTransform: "none",
              }}
              variant="body2"
            >
              {moment(clip.HighlightDate).format("MMMM DD, YYYY")}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShortContentCard;
